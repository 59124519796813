import { Typography } from '@mui/material';
import { Box } from './Box';
import {
  Info24Regular,
  Dismiss24Regular,
  Warning24Regular,
} from '@fluentui/react-icons';

export interface BannerNoticeProps {
  text: string;
  type: 'info' | 'warning';
  onClose: () => void;
  className?: string;
}

export const BannerNotice = ({
  text,
  onClose,
  type = 'info',
  className,
}: BannerNoticeProps) => {
  const _className =
    `ecs-banner-notice ecs-banner-notice-${type}` + (className ?? '');

  //can apply custom className
  return (
    <>
      <Box
        className={_className}
        direction='row'
        padding='small'
        justifyContent='space-between'
        alignItems='center'
      >
        {type === 'info' ? (
          <Info24Regular className='ecs-banner-notice-icon' />
        ) : (
          <Warning24Regular className='ecs-banner-notice-icon' />
        )}
        <Box background='none'>
          <Typography sx={{wordBreak: 'break-word', whiteSpace: 'normal'}} className='ecs-banner-notice-text' variant='h2' >
            {text}
          </Typography>
        </Box>

        <Dismiss24Regular
          className='ecs-banner-notice-icon ecs-banner-notice-dismiss'
          onClick={onClose}
        />
      </Box>
    </>
  );
};
