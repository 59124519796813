import { App } from '@encompaas/common';
import { useMsalLogout } from '@encompaas/common/auth';
import {
  AppBarWithActions,
  BannerNotice,
  Box,
} from '@encompaas/common/components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Applications } from '../components/Applications';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser } from '@encompaas/common/hooks';
import { Disposal, DisposalApproval, Imc } from '@encompaas/common/icons';
import { AppPage } from './AppPage';
import { config } from '@encompaas/common';

export const HomePage = () => {
  const user = useUser();
  const onLogout = useMsalLogout();

  // Create a mapping from app name to disposal icons
  const appLogos: { [name: string]: (props: any) => JSX.Element } = {
    IMC: Imc,
    Disposal: Disposal,
    'Disposal Approval': DisposalApproval,
  };

  // Create a list of applications that the user has access to for rendering
  const applications =
    user && user['ConfiguredApps']
      ? user['ConfiguredApps']
          .filter((app: App) => app.IsAuthorised) // Only show apps we are authorised to use
          .map((app: App) => {
            return {
              name: app.DisplayName,
              Logo: appLogos[app.DisplayName],
              url: app.Homepage,
            };
          })
      : [];

  // console.log(applications);

  return (
    <AppPage data-testid={TEST_CONSTANTS.HOME_PAGE}>
      <Box className={'apps-page-container'} background='none'>
        <AppBarWithActions
          user={user}
          color='secondary'
          data-testid={TEST_CONSTANTS.APP_BAR}
          onLogout={onLogout}
          onHelpOption={(option: string) => console.log(option)}
          helpOptions={[
            'Help',
            'Community',
            'Contact us',
            'About',
            `${config.TITLE} v${config.VERSION}`,
          ]}
          background='none'
          height={5}
        />

        <Applications applications={applications} />
      </Box>
    </AppPage>
  );
};
