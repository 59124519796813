import * as React from 'react';
import { SVGProps } from 'react';
const SvgDisposalApproval = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 70 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m11.869 18.881 2.898 39.76c.208 2.831 2.623 5.109 5.523 5.109H47.14c2.9 0 5.315-2.209 5.523-5.109l2.967-39.758H11.87v-.002Z'
      fill='#DCE1EF'
    />
    <path
      d='M23.125 18.125V13.75a3.75 3.75 0 0 1 3.75-3.75H40a3.75 3.75 0 0 1 3.75 3.75v4.375m-37.5.625h54.375'
      stroke='#DCE1EF'
      strokeWidth={2}
      strokeLinecap='round'
    />
    <path
      d='M43.75 70.625c10.7 0 19.375-8.675 19.375-19.375S54.45 31.875 43.75 31.875 24.375 40.55 24.375 51.25 33.05 70.625 43.75 70.625Z'
      fill='#fff'
      stroke='url(#DisposalApproval_svg__a)'
    />
    <path
      d='m35 51.666 5.555 5.556L52.778 45'
      stroke='url(#DisposalApproval_svg__b)'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='DisposalApproval_svg__a'
        x1={26.343}
        y1={15.095}
        x2={89.684}
        y2={53.816}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.66} />
      </linearGradient>
      <linearGradient
        id='DisposalApproval_svg__b'
        x1={36.153}
        y1={38.295}
        x2={59.594}
        y2={57.195}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.66} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgDisposalApproval;
