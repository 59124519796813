import * as React from 'react';
import { SVGProps } from 'react';
const SvgDisposal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 70 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m44.455 26.931-3.433 4.357-2.96 2.046-3.87-1.64-1.838-1.835-2.202-3.31-.982-8.209 9.674 4.1 5.61 4.491Z'
      fill='#DCE1EF'
    />
    <path
      d='M33.75 29.63 23.38 30l-6.668-.37-2.962-2.223v-1.482l1.481-2.223L24.863 20l7.407 5.555 1.48 4.075Zm12.65-5.727 2.128 5.105.57 3.53-2.45 2.415-1.947.607-3.154.162-6.39-3.362 6.126-6.035 5.117-2.422Z'
      fill='#DCE1EF'
    />
    <path
      d='M14.422 26.875h42.403L53.909 66.08v.002a4.89 4.89 0 0 1-4.897 4.543H22.17c-2.57 0-4.714-2.029-4.898-4.544l-2.85-39.206h.001Z'
      fill='#fff'
      stroke='url(#Disposal_svg__a)'
    />
    <path
      d='m24.869 16.756.743-4.311a3.75 3.75 0 0 1 4.333-3.058L42.88 11.62a3.75 3.75 0 0 1 3.057 4.334l-.743 4.31'
      stroke='url(#Disposal_svg__b)'
    />
    <path d='m8.75 14.505 53.584 9.244' stroke='url(#Disposal_svg__c)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.25 60.656a.626.626 0 1 0 1.25-.062l-1.25-25a.625.625 0 1 0-1.25.062l1.25 25Zm19.406-25.655a.625.625 0 0 1 .593.655l-1.25 25a.625.625 0 0 1-1.248-.062l1.25-25a.625.625 0 0 1 .655-.593ZM35.625 35a.625.625 0 0 1 .625.625v25a.625.625 0 1 1-1.25 0v-25a.625.625 0 0 1 .625-.625Z'
      fill='url(#Disposal_svg__d)'
    />
    <defs>
      <linearGradient
        id='Disposal_svg__a'
        x1={16.586}
        y1={8.075}
        x2={86.914}
        y2={49.874}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.66} />
      </linearGradient>
      <linearGradient
        id='Disposal_svg__b'
        x1={28.125}
        y1={5.744}
        x2={37.625}
        y2={28.113}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.66} />
      </linearGradient>
      <linearGradient
        id='Disposal_svg__c'
        x1={12.309}
        y1={14.606}
        x2={11.719}
        y2={19.011}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.66} />
      </linearGradient>
      <linearGradient
        id='Disposal_svg__d'
        x1={18.75}
        y1={30.625}
        x2={64.726}
        y2={36.45}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.66} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgDisposal;
