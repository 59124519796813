import { useLogin } from '@encompaas/common/auth';
import { AppLogo, Box } from '@encompaas/common/components';
import { Typography } from '@mui/material';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { AppPage } from './AppPage';

export const LoginPage = () => {
  const { onLogin } = useLogin();

  return (
    <AppPage data-testid={TEST_CONSTANTS.LOGIN_PAGE}>
      <Box
        alignItems='start'
        justifyContent='start'
        padding='large'
        background='light'
        rounded='all'
        direction='column'
        style={{
          margin: 'auto',
          paddingLeft: 40,
        }}
        width={40}
        height={25}
        shadow
      >
        <AppLogo colour={'light'} />
        <Box height={2.5} />
        <Box background='light' style={{ paddingLeft: 20 }}>
          <Typography variant='body2'>
            <span
              onClick={onLogin}
              style={{ textDecoration: 'underline', cursor: 'pointer', marginRight: '0.3rem', }}
            >
              Log in
            </span>
            to access EncompaaS apps
          </Typography>
        </Box>
      </Box>
    </AppPage>
  );
};
