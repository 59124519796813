import { Box } from '@encompaas/common/components';
import { Typography } from '@mui/material';

export type PanelProps = {
  children: React.ReactNode;
};

//this should be named something like AppsPanel so that it is clear the it isn't a
//generic component
export const Panel = ({ children }: PanelProps) => {
  return (
    <Box
      className='apps-apps-panel'
      alignItems='start'
      justifyContent='start'
      background='none'
      rounded='all'
      direction='column'
      shadow
    >
      <Box className={'apps-apps-header'} background='none' height={7}>
        <Typography variant='h1'>Applications</Typography>
      </Box>

      {children}
    </Box> 
  );
};
